import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {SearchFormComponent} from './search-form.component';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';

@NgModule({
  declarations: [SearchFormComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    BsDatepickerModule.forRoot()
  ],
  exports: [SearchFormComponent]
})

/**
 *
 *
 * @export
 * @class SearchFormModule
 */
export class SearchFormModule {}
