/**
 *
 *
 * @export
 * @class LoginConstants
 */
export class LoginConstants {
  static readonly LOGGED_OUT_MESSAGE_REGULAR = 'Your session has timed out.  Please login again.';
  static readonly LOGGED_OUT_MESSAGE_AUTOSAVED = 'Your unsaved changes have been saved before logging out. Please login again.';
  static readonly AUTOSAVED ='AUTOSAVED';
  static readonly REGULAR = 'REGULAR';
}
