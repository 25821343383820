/* eslint-disable no-invalid-this*/

import {Injectable} from '@angular/core';
import {BaseService} from './base.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Global} from './../../../global.component';
import {EncryptService} from './encrypt.service';
import {Observable} from 'rxjs';
import {SharedData} from '../interfaces/commondata.interface';
import {Router} from '@angular/router'
import {Subject} from 'rxjs/Subject';
@Injectable()

/**
 * This service is used to make use of some global service
 *
 * @export
 * @class CommonService
 * @extends {BaseService}
 */
export class CommonService extends BaseService {
  headers = {};
  options = {};
  auditRecordheaders = {};
  auditRecordoptions = {};
  encryptService: EncryptService;

  private notify = new Subject<any>();
  /**
   * Observable string streams
   */
  notifyObservable$ = this.notify.asObservable();


  /**
   *Creates an instance of CommonService.
   * @param {HttpClient} http
   * @param {EncryptService} encryptservice
   * @memberof CommonService
   */
  constructor(public http: HttpClient, private encryptservice: EncryptService, private router: Router) {
    // initializing all the header parameters

    super(http);
    this.headers['Content-Type'] = 'application/json';
    this.auditRecordheaders['Content-Type'] = 'application/json';
    this.headers['Authorization'] = sessionStorage.getItem('token');
    if (sessionStorage.getItem('userType') == 'Administrator on Demand') {
      this.headers['orgId'] = sessionStorage.getItem('orgId')
    }
    const httpHeaders = new HttpHeaders(this.headers);
    this.options['headers'] = httpHeaders;
    this.options['observe'] = 'response';

    const httpAuditHeaders = new HttpHeaders(this.auditRecordheaders);
    this.auditRecordoptions['headers'] = httpAuditHeaders;
    this.auditRecordoptions['observe'] = 'response';
    this.auditRecordoptions['module'] = 'auditlog';

    this.encryptService = encryptservice;
  }

  /**
   *
   *
   * @param {*} formData
   * @return {any} audit record success msg
   * @memberof CommonService
   */
  insertAuditRecords(formData): any {
    const encryptedPayload = this.encryptService.encryptAuditPayload(JSON.stringify(formData));
    return this.post(Global.getAPIURI('addAuditRecord'), encryptedPayload, this.auditRecordoptions);
  }

  /**
   *
   *
   * @param {*} permissionArray
   * @return {object} modulePermission
   * @memberof CommonService
   */
  getModulePermission(permissionArray): object {
    const modulePermission = {'view': false, 'add': false, 'edit': false, 'delete': false};
    if (permissionArray.includes('V')) modulePermission['view'] = true;
    if (permissionArray.includes('E')) modulePermission['edit'] = true;
    if (permissionArray.includes('A')) modulePermission['add'] = true;
    if (permissionArray.includes('D')) modulePermission['delete'] = true;
    return modulePermission;
  }

  /**
   * This common service will be used to sort the Items alphabetically
   *
   * @param {*} arrObject
   * @param {*} fieldName
   * @param {*} order
   * @return {any}
   * @memberof CommonService
   */
  sortColumn(arrObject, fieldName, order = 'asc'): any {
    // to return empty on empty array
    if (arrObject.length == 0) return [];
    const sortedObject = arrObject.sort((t1, t2): number => {
      let name1 = t1[fieldName];
      let name2 = t2[fieldName];

      // to sort all the strings
      if (isNaN(name1) == true && isNaN(name2) == true) {
        name1 = name1.toLowerCase();
        name2 = name2.toLowerCase();
        if (name1 > name2 && order == 'asc') {
          return 1;
        } else {
          return -1;
        }
        return 0;
      } else {
        // to sort all the numbers
        if (order == 'asc') return name1 - name2;
        else return name2 - name1;
      }
    });

    return sortedObject;
  }

  /**
   * This method will help us the find the level 3
   * navigations that are used along with selected options
   * @param {string} parentModule
   * @param {string} activeModule
   * @return {*}
   * @memberof CommonService
   */
  getSubNavigation(parentModule: string, activeModule: string): any {
    let result = [];
    const navigationModules = JSON.parse(sessionStorage.getItem('mainNavigation'));
    // to find the parent module to display their sub menus
    for (const index in navigationModules) {
      if (navigationModules[index]['children'].length == 0) continue;
      for (const index2 in navigationModules[index]['children']) {
        const module = navigationModules[index]['children'][index2];
        if (module['moduleName'] == parentModule) {
          result = module['children']; // return level 3 menu item
        }
      }
    }

    // to find the active module
    for (const index in result) {
      if (result[index]['moduleName'] == activeModule) {
        result[index]['selectedCSS'] = 'color active';
        result[index]['selected'] = true;
      } else {
        result[index]['selectedCSS'] = 'color';
        result[index]['selected'] = false;
      }
    }
    return result;
  }

  /**
   * This module will help us to identify if the feature
   * is released or not based on the navigation menu available
   *
   * @param {string} moduleName
   * @return {boolean}
   * @memberof CommonService
   */
  isFeatureReleased(moduleName): boolean {
    const navigationModules = JSON.parse(sessionStorage.getItem('mainNavigation'));
    var isFeatureReleased = function(module): boolean {
      moduleName = this.toString();
      if (module['moduleName'] === moduleName) return true;
      else if (typeof (module['children']) !== 'undefined' && module['children'].length > 0) {
        return module['children'].some(isFeatureReleased, moduleName);
      } else return false;
    }
    // .some() iterates array till it gets a true, is uses a callback function to achieve this
    return navigationModules.some(isFeatureReleased, moduleName);
  }

  /**
   * To check if the access is available based on the
   * Featured released
   *
   * @param {string} url
   * @return {boolean}
   * @memberof CommonService
   */
  isAccessGranted(url: string): boolean {
    url = url.replace('/', '');
    const releasedModules = JSON.parse(sessionStorage.getItem('mainNavigation'));
    var isAccessGranted = function(module): boolean {
      url = this.toString();
      if (url.includes(module['url'])) return true;
      else if (typeof (module['children']) !== 'undefined' && module['children'].length > 0) {
        return module['children'].some(isAccessGranted, url);
      } else return false;
    }
    // .some() iterates array till it gets a true, is uses a callback function to achieve this
    return releasedModules.some(isAccessGranted, url);
  }

  /**
   * @param {*} time
   * @return {any} timezone
   * @memberof CommonService
   */
  getUTCTimezone(time): any {
    const utcTime = "UTC" + time
    return utcTime
  }

  /**
   * This method will convert time format HH:MM to actual minutes
   *
   * @param {*} strTime
   * @return {number}
   * @memberof CommonService
   */
  stringToMinutes(strTime): number {
    const timeArray = strTime.split(":");
    let totalMinutes = 0;
    if (timeArray.length == 2) totalMinutes = parseInt(timeArray[0]) * 60 + parseInt(timeArray[1]);
    else totalMinutes = parseInt(timeArray[0]) * 60;
    return totalMinutes;
  }

  /**
  * This method will convert the actual minutes to Hours strings in HH:MM
  *
  * @param {*} minutes
  * @return {string}
  * @memberof CommonService
  */
  minutesToHours(minutes): string {
    const hoursArray = (minutes / 60).toString().split('.');
    const hours = (hoursArray[0].length == 1) ? `0${hoursArray[0]}` : hoursArray[0];
    let mins: number | string = (minutes % 60);
    mins = (mins < 0) ? -(mins) : mins;
    mins = mins.toString();
    mins = (mins.length == 1) ? `0${mins}` : mins;
    return `${hours}:${mins}`;
  }

  /**
    * This method will help us to filter out the data based on the
    * requirement, the filtervalue is case insensitive whereas
    * the filterField is case sensitive
    *
    * @param {object[]} arrObj
    * @param {string} filterField
    * @param {string} filterValue
    * @return {object[]}
    * @memberof CommonService
    */
  filterDataRecords(arrObj: object[], filterField: string, filterValue: string): object[] {
    let filteredRecords = [];
    if (typeof (arrObj) !== 'object' || arrObj == null) return filteredRecords;

    filteredRecords = arrObj.filter((val): boolean => {
      if (typeof (val[filterField]) !== 'undefined' && val[filterField].toLowerCase() == filterValue.toLowerCase()) return true
    });
    return filteredRecords;
  }

  /**
   * This will decide whether features or released or not for customer administrator
   * If feature is found, it returns true
   * @param {object[]} menu
   * @param {string} moduleName
   * @return {boolean}
   * @memberof CommonService
   */
  checkReleasedAdminFeatures(menu: object[], moduleName: string): boolean {
    const level1 = menu.find((item): boolean => {
      if (item['moduleName'] === moduleName) return true;
      if (item['children'].length > 0) {
        const level2 = item['children'].find((subitem): boolean => {
          if (subitem.moduleName === moduleName) return true;
          if (subitem.children.length > 0) {
            const level3 = subitem.children.find((innerItem): boolean => {
              if (innerItem.moduleName === moduleName) return true;
            });
            if (typeof (level3) !== 'undefined') return true;
          }
        });
        if (typeof (level2) !== 'undefined') return true;
      }
    });

    return (typeof (level1) === 'undefined') ? false : true;
  }

  /**
 * Method to validate all the callflowlists
 * before deleting them
   *
   * @param {string} callflowListID
   * @return {Observable<any>}
   * @memberof CommonService
   */
  validateCallFlowListBeforeDelete(callflowListID: string): Observable<any> {
    return this.get(`${Global.getAPIURI('validateCallflowListBeforeDelete')}?ref=${callflowListID}`, this.options)
  }

  /**
 *Method to redirect the callflow page
 * after  clicking on  hyperlink
 * @param {any} callflowvalue
 * @param {any} modalReference
 *  @memberof commomservice
 */

  public redirectingCallFlows(callflowvalue: any, modalReference: any): void {
    if (modalReference!=undefined) {
      modalReference.close();
    }
    const morefields = JSON.parse(sessionStorage.getItem('moreFields'));
    const permissions = JSON.parse(sessionStorage.getItem('userPermission'));
    if ((permissions['Call Flow Editor Beta'] && permissions['Call Flow Editor Beta'].indexOf('V') != -1)
      && (morefields['betaMode'] == undefined || morefields['betaMode'] == true)) {
      this.router.navigate([`/react-callflow-editor/` + callflowvalue['callFlowId'] + `/version/` + callflowvalue['versionId']]);
    } else {
      this.router.navigate([`/call-flow-editor/` + callflowvalue['callFlowId'] + `/version/` + callflowvalue['versionId']]);
    }
  }

  public notifyOther(data: any) {
    if (data) {
      this.notify.next(data);
    }
  }
}
