/**
 * All alert related constants
 * are found here
 *
 * @export
 * @class AlertConst
 */
export class AlertConst {
  static readonly TIMEOUT = 6000 // milliseconds

  // alert css constants
  static readonly ALERT_CLASS = 'alert';
  static readonly ALERT_SUCCESS = 'alert-success';
  static readonly ALERT_WARNING = 'alert-warning';
  static readonly ALERT_INFO = 'alert-info';
  static readonly ALERT_ERROR = 'alert-danger';

  // alert icon-constants
  static readonly ICON_SUCCESS = 'fa-check-circle';
  static readonly ICON_ERROR = 'fa-times-circle';
  static readonly ICON_INFO = 'fa-info-circle';
  static readonly ICON_WARNING = 'fa-exclamation-circle';
}
