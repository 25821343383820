/**
 * Global constants related to this project
 *
 * @export
 * @class Constants
 */
export class Constants {
  static readonly DATE_FMT = 'dd MMM yyyy';
  static readonly DATE_FMT_WITHOUT_YEAR = 'dd MMM';
  static readonly DATE_TIME_FMT = `dd MMM  yyyy H:mm`;
  static readonly TIME_FMT = `H:mm`;
  static readonly TIMEWITHSECONDS_FMT = `H:mm:ss:SSS`;
  static readonly MONTH_FMT = `MMM-yyyy`;
}
