
/**
 * This activity type defines all the acitivity types
 * used in this Application
 *
 * @export
 * @class ActivityType
 */
export class ActivityType {
  public static EDIT = "edit";
  public static LOGIN = "login";
  public static LOGOUT = "logout";
  public static INSERT = "insert";
  public static DELETE = "delete";
  public static DELETEALL = "deleteAll";
  public static DOWNLOAD = "download";
  public static ACCESS_ORG = "access";
  public static RELEASE_ORG = "release";
  public static VIEW = "view";
  public static CEASE = "cease";
  public static UNCEASE = "uncease";
  public static BLOCK = "block CLI";
  public static WEBHOOK = "webhook";
  public static ASSIGN = "assign";
  public static PROPOSE = "propose";
  public static ENABLE = "enable";
  public static DISABLE = "disable";
  public static RESET = "reset";
  public static CONFIRM = "confirm";
}
