import {NgModule} from '@angular/core';
// import {BrowserModule} from '@angular/platform-browser';
import {CommonModule} from '@angular/common';
import {AudioPlayerComponent} from './audio-player.component';
import {NgxAudioPlayerModule} from 'ngx-audio-player';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {CommonService} from '../../../shared/services/common.service';
// import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule} from '@angular/forms';
@NgModule({
  declarations: [AudioPlayerComponent],
  imports: [
    // BrowserModule,
    FormsModule,
    // BrowserAnimationsModule,
    CommonModule,
    NgxAudioPlayerModule,
    NgbModule.forRoot()
  ],
  exports: [AudioPlayerComponent],
  providers: [CommonService]
})

/**
 *
 *
 * @export
 * @class AudioPlayerModule
 */
export class AudioPlayerModule {}
