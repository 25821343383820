import {Injectable} from '@angular/core';
import {CanActivate} from '@angular/router';
import {Router} from '@angular/router';

@Injectable()

/**
 *
 *
 * @export
 * @class AuthGuard
 * @implements {CanActivate}
 */
export class AuthGuard implements CanActivate {
  /**
   *Creates an instance of AuthGuard.
   * @param {Router} router
   * @memberof AuthGuard
   */
  constructor(private router: Router) {}

  /**
   *
   *
   * @return {boolean}
   * @memberof AuthGuard
   */
  canActivate(): boolean {
    if (sessionStorage.getItem('isLoggedin')) {
      return true;
    }

    this.router.navigate(['/login']);
    return false;
  }
}
