/**
 * Enum for the available searchType
 *
 * @export
 * @enum {number}
 */
export enum SearchType {
  INPUT = 'input',
  SELECT = 'select',
  DATERANGE = 'dateRange'

}
