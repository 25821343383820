import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import * as CryptoJS from 'crypto-js';
import {Socket} from 'ng-socket-io';

@Injectable()

export class EncryptService {
  userID: string;
  t: string;
  p: string;
  constructor(private socket: Socket) {
    this.userID = sessionStorage.getItem('userID');
    this.socket.emit('pingT', {});
    this.socket.on('getGlobalVariables', (msg) => {
      const u = JSON.parse(JSON.stringify(msg));
      this.t = u['t'];
      this.p = u['p'];
    });
  }

  reverseString(str) {
    return str.replace(/-/g, "").split("").reverse().join("");
  }

  encryption(dataToEncrypt) {
    if (this.t === undefined) {
      this.getVar();
    } // recursive function
    if (this.t != undefined) {
      var userID = this.t;
      var passphr = this.p;
      var keyComb = passphr.concat(userID);
      var key = CryptoJS.enc.Utf8.parse(keyComb);
      const id = (CryptoJS.lib.WordArray.random(16)).toString();
      var iv = CryptoJS.enc.Utf8.parse(id);
      var encrypted = CryptoJS.AES.encrypt(dataToEncrypt, key, {
        keySize: 256 / 32,
        iv: iv, // Initialization vector. The input has to be in hexadecimal form. It is strongly recommended to use any other value than the default one for increasing security.
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });
      const finalStr = id + "$" + encrypted.toString()
      return finalStr;
    }
  }

  decryption(dataToDecrypt) {
    if (this.t === undefined) {
      this.getVar();
    } // recursive function
    if (this.t != undefined) {
      const data = dataToDecrypt.split("$");
      var userID = this.t;
      var passphr = this.p;
      var keyComb = passphr.concat(userID);
      var key = CryptoJS.enc.Utf8.parse(keyComb);
      var iv = CryptoJS.enc.Utf8.parse(data[0]);
      var decrypted = CryptoJS.AES.decrypt(data[1], key, {
        keySize: 256 / 32,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });
      return (JSON.parse(decrypted.toString(CryptoJS.enc.Utf8)));
    }
  }
  
  
  decryption2(dataToDecrypt, key) {
    const data = dataToDecrypt.split("$");
      
      var key2 = CryptoJS.enc.Utf8.parse(key);
      var iv = CryptoJS.enc.Utf8.parse(data[0]);
      var decrypted = CryptoJS.AES.decrypt(data[1], key2, {
        keySize: 256 / 32,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });
	 // console.log(decrypted);
      return (JSON.parse(decrypted.toString(CryptoJS.enc.Utf8)));
  }

  encryptAuditPayload(auditPayLoad) {
    if (this.t === undefined) {
      this.getVar();
    } // recursive function
    var passphr = this.p;
    var keyComb = passphr.concat(this.t);
    var key = CryptoJS.enc.Utf8.parse(keyComb);
    const id = (CryptoJS.lib.WordArray.random(16)).toString();
    var iv = CryptoJS.enc.Utf8.parse(id);
    var encrypted = CryptoJS.AES.encrypt(auditPayLoad, key, {
      keySize: 256 / 32,
      iv: iv, // Initialization vector. The input has to be in hexadecimal form. It is strongly recommended to use any other value than the default one for increasing security.
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    const finalStr = id + "$" + encrypted.toString()
    return finalStr;
  }


  // encryptORDecrypt(auditPayLoad) {
  // 	var passphr = xxxxxxxx;
  // 	var keyComb = passphr.concat(EncryptService.tokenLessPhrase);
  // 	var key = CryptoJS.enc.Utf8.parse(keyComb);
  // 	// console.log(keyComb);
  // 	let id = (CryptoJS.lib.WordArray.random(16)).toString();
  // 	console.log(id)
  // 	var iv = CryptoJS.enc.Utf8.parse(id); //CryptoJS.enc.Utf8.parse((CryptoJS.lib.WordArray.random(4)).toString()); //(CryptoJS.lib.WordArray.random(16)).toString();
  // 	console.log(iv);
  // 	// console.log(CryptoJS.randomBytes(16));
  // 	var encrypted = CryptoJS.AES.encrypt(auditPayLoad, key, {
  // 		keySize: 256 / 32,
  // 		iv: iv, //Initialization vector. The input has to be in hexadecimal form. It is strongly recommended to use any other value than the default one for increasing security.
  // 		mode: CryptoJS.mode.CBC,
  // 		padding: CryptoJS.pad.Pkcs7
  // 	});
  // 	this.decryptNew(id + "$" + encrypted.toString());
  // 	return "dd";
  // }

  // decryptNew(data) {
  // 	let v = data.split("$")
  // 	var passphr = xxxxxxxxx;
  // 	var keyComb = passphr.concat(EncryptService.tokenLessPhrase);
  // 	var key = CryptoJS.enc.Utf8.parse(keyComb);
  // 	var iv = CryptoJS.enc.Utf8.parse(v[0]);//data[0];
  // 	var decrypted = CryptoJS.AES.decrypt(v[1], key, {
  // 		keySize: 256 / 32,
  // 		iv: iv,
  // 		mode: CryptoJS.mode.CBC,
  // 		padding: CryptoJS.pad.Pkcs7
  // 	});
  // 	return (JSON.parse(decrypted.toString(CryptoJS.enc.Utf8)));
  // }

  getVar() {
    if (this.t === undefined) {
      this.socket.emit('pingT', {});
      return this.getVar();
    } else {
      return true;
    }
  }
  
  /*
	@AUTHOR: Sovan Dey
	UCP-6932
	return: will return plain text
	accept: will take encoded text
	*/
  decryptionPlainText(dataToDecrypt) {
    if (this.t === undefined) {
      this.getVar();
    } // recursive function
    if (this.t != undefined) {
      const data = dataToDecrypt.split("$");
      var userID = this.t;
      var passphr = this.p;
      var keyComb = passphr.concat(userID);
      var key = CryptoJS.enc.Utf8.parse(keyComb);
      var iv = CryptoJS.enc.Utf8.parse(data[0]);
      var decrypted = CryptoJS.AES.decrypt(data[1], key, {
        keySize: 256 / 32,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });
      return (decrypted.toString(CryptoJS.enc.Utf8));
    }
  }
}
