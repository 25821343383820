/* eslint-disable */
import { Injectable } from '@angular/core';
import {environment} from './../../environments/environment';
import { BaseService } from './../shared';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Global } from '../../global.component';
import { CommonService, EncryptService } from './../shared/services';
@Injectable()

export class LoginService extends BaseService{

	headers = {};
	options = {};

	constructor(public http:HttpClient, private encryptService: EncryptService) {
		super(http);
	}

	setHeaders(idToken){
		this.headers['Content-Type'] = 'application/json';
		this.headers['Authorization'] = idToken;
		let httpHeaders = new HttpHeaders(this.headers);
		return this.options = {headers:httpHeaders};
	}

	checkAPIGateway(formData) {
		this.options = this.setHeaders(formData['idToken']);
		return this.get(Global.getAPIURI('checkAPIGateway'), this.options);
	}


	logINWithMFA(formData){
		this.headers['Content-Type'] = 'application/json';
		let httpHeaders = new HttpHeaders(this.headers);
		this.options = {headers:httpHeaders, observe: "response" as 'body',   responseType: "json"};
		let payload = formData;
		payload["operation"] = "logIN";
		return this.post(Global.getAPIURI('logIN'), this.encryptService.encryption(JSON.stringify(payload)),this.options );
	}

	getProductName() {		
		return this.get(Global.getAPIURI('getProductName'));
	}
  
  
	getCostCentre(formData) {
		this.options = this.setHeaders(formData['idToken']);
		delete(formData['idToken']);
		let payload = formData;
		payload["operation"] = "listCostCentre";
		return this.post(Global.getAPIURI('organisationList'), payload, this.options);
	}

	getOrganization(formData) {
		this.options = this.setHeaders(formData['idToken']);
		let payload = {
			"operation": "listOrganization"
		};
		return this.post(Global.getAPIURI('organisationList'), payload, this.options);
	}

	role(formData, operation){
		this.options = this.setHeaders(formData['idToken']);
		delete(formData['idToken']);
		let payload = formData;
		payload["operation"] = operation;
		return this.post(Global.getAPIURI('getroleList'), payload, this.options);
	}

    getNavigation(idToken) {
      this.options = this.setHeaders(idToken);
      let payload = {
        "service": "mainNavigation"
      };
      return this.post(Global.getAPIURI('uiConfig'), payload, this.options);
    }
	/*
	@AUTHOR: Sovan Dey
	UCP-6932
	return: will a json of username and password
	accept: will take base64decoded text
	*/
	getTempPassword(token){
	  let payload = {
		"approvalToken": token
      };
      return this.post(Global.getAPIURI('getTempPassword'), payload, this.options);
	}

}
