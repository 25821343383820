import {Pipe, PipeTransform} from '@angular/core';
import {environment} from '../../../environments/environment';

@Pipe({
  name: 'limitContent'
})

/**
 * This pipe will limit the content to be listed in the CLI list
 *
 * @export
 * @class ContentLimitPipe
 * @extends {DatePipe}
 * @implements {PipeTransform}
 */
export class ContentLimitPipe implements PipeTransform {
  /**
   *
   *
   * @param {*} value
   * @param {*} [limit]
   * @return {*}
   * @memberof ContentLimitPipe
   */
  transform(value: any, limit?: any): any {
    const contentLimit = (typeof(limit)==='undefined')? environment.contentLimitInList : limit
    let content = value;
    if (value.length > contentLimit) {
      content = `${value.substr(0, contentLimit)}...`
    }
    return content;
  }
}
