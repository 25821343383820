import {Component, OnInit, HostListener} from '@angular/core';
import {Title} from '@angular/platform-browser'
import {LoginService} from './login/login.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

/**
 *
 *
 * @export
 * @class AppComponent
 * @implements {OnInit}
 */
export class AppComponent implements OnInit {
  title = 'My project';
  /**
   *Creates an instance of AppComponent.
   * @memberof AppComponent
   */
  constructor(
    private titleService: Title,
    public loginService: LoginService
  ) {}

  /**
   *
   *
   * @memberof AppComponent
   */
  ngOnInit(): void {
    this.loginService.getProductName().subscribe((sData: any) => {
      this.titleService.setTitle(sData.data);
    });
  }

  /**
   * This method will update the
   * lastactivityTime to keep a track of user's active interaction
   * with IV portal, it is done by tracking the user's mouse up
   * movements
   *
   * @param {*} e
   * @memberof AppComponent
   */
  @HostListener("mouseup", ["$event"]) mouseUp(e): void {
    sessionStorage.setItem('lastActivityTime', new Date().getTime().toString());
  }
}
