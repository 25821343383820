import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AppComponent} from './app.component';
import {AuthGuard} from './shared';

const routes: Routes = [
  {path: '', loadChildren: () => import('./layout/layout.module').then((m) => m.LayoutModule), canActivate: [AuthGuard]},
  {path: 'login', loadChildren: () => import('./login/login.module').then((m) => m.LoginModule)},
  {path: 'login/:approvalID', loadChildren: () => import('./login/login.module').then((m) => m.LoginModule)},
  {path: 'forgot', loadChildren: () => import('./forgot-password/forgot-password.module').then((m) => m.ForgotPasswordModule)},
  {path: 'confirm', loadChildren: () => import('./confirm-password/confirm-password.module').then((m) => m.ConfirmPasswordModule)},
  {path: 'signup', loadChildren: () => import('./signup/signup.module').then((m) => m.SignupModule)},
  {path: 'error', loadChildren: () => import('./server-error/server-error.module').then((m) => m.ServerErrorModule)},
  {path: 'webrtc', loadChildren: () => import('./webrtc/webrtc.module').then((m) => m.WebrtcModule)},
  {path: 'access-denied', loadChildren: () => import('./access-denied/access-denied.module').then((m) => m.AccessDeniedModule)},
  {path: 'not-found', loadChildren: () => import('./not-found/not-found.module').then((m) => m.NotFoundModule)},
  {path: 'user-guide', loadChildren: () => import('./user-guide/user-guide.module').then((m) => m.UserGuideModule)},
  {path: 'approve-mrc-request/:ID', loadChildren: () => import('./approve-mrc-request/approve-mrc-request.module').then((m) => m.ApproveMRCRequestModule)},
  {path: 'supplier-bill/:ID', loadChildren: () => import('./supplier-bill/supplier-bill.module').then((m) => m.SupplierBillModule)},
  {path: 'queues-status', loadChildren: () => import('./queues-status/queues-status.module').then((m) => m.QueuesStatusModule)},
  {path: '**', loadChildren: () => import('./not-found/not-found.module').then((m) => m.NotFoundModule)}

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
// eslint-disable-next-line require-jsdoc
export class AppRoutingModule { }
