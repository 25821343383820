import {Injectable, Injector} from '@angular/core';
import {CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {CommonService} from './../services/common.service';
import {Generic} from './../common/generic.class';
@Injectable()

/**
 * This class is created to check the
 * user type and restrict the access
 *
 * @export
 * @class RoleGuard
 * @implements {CanActivate}
 */
export class RoleGuard implements CanActivate {
  commonService: any;
  static readonly ACCESS_RESTRICTED_URL = '/access-denied';
  /**
   *Creates an instance of RoleGuard.
   * @param {Router} router
   * @param {Injector} injector
   * @memberof RoleGuard
   */
  constructor(private router: Router, private injector: Injector) {
    this.commonService = this.injector.get(CommonService);
  }

  /**
   * This method will check if that user type has access to
   * navigate to the page which they are requesting
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @return {boolean}
   * @memberof RoleGuard
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    // Updating the logged in user details
    if (sessionStorage.getItem("isAuditRecordAddedForLogin") == "false") {
     
      setTimeout(() => {
        let auditRecordItem = JSON.parse(sessionStorage.getItem("loggedInUserDetails"));
        this.commonService.insertAuditRecords(auditRecordItem).subscribe((response: any): any => {
          sessionStorage.setItem("isAuditRecordAddedForLogin", "true");
        }, error => {});

      }, 3000);
        //removing MFA secret after successful login
        var moreFields = JSON.parse(sessionStorage.getItem("moreFields"));
        delete (moreFields['MFA']);
        sessionStorage.setItem("moreFields", JSON.stringify(moreFields));
    }
    // Authorizing the user based on their permissions
    const restrictedAccess = RoleGuard.ACCESS_RESTRICTED_URL;
    if (this.commonService.isAccessGranted(state.url) == false) {
      this.router.navigate([restrictedAccess]);
      return false;
    }
    // Adding this condtion to avoid Admin logging into customer admin's page, then it will throw access denied.:D:D
    // this session is being created to login in user to same page from their last session only when their 
    // browser session is alive, later it could be done using localStorage for making it persistent
    if (sessionStorage.getItem('userType')=== Generic.ADMIN_ON_DEMAND) sessionStorage.setItem(`lastVisitedPage_${Generic.ADMIN_ON_DEMAND}_${sessionStorage.getItem('userID')}`, decodeURIComponent(state.url));
    else  sessionStorage.setItem(`lastVisitedPage_${sessionStorage.getItem('userID')}`, decodeURIComponent(state.url));
    return true;
  }
}
