import {NgModule} from "@angular/core";
import {ContentLimitPipe} from "./../pipes";

@NgModule({
  declarations: [ContentLimitPipe],
  imports: [],
  exports: [ContentLimitPipe]
})

/**
 * This module needs to be imported to get a pipe to work
 *
 * @export
 * @class CommonPipeModule
 */
export class CommonPipesModule {}
