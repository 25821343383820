/**
 * Alert class to clear an alert object
 *
 * @export
 * @class Alert
 */
export class Alert {
  type: AlertType;
  message: string;
  alertId: string;
  keepAfterRouteChange: boolean;

  /**
   *Creates an instance of Alert.
   * @param {Partial<Alert>} [init]
   * @memberof Alert
   */
  constructor(init?: Partial<Alert>) {
    Object.assign(this, init);
  }
}

/**
 * Alert enum for all the alert Types
 *
 * @export
 * @enum {number}
 */
export enum AlertType {
  Success,
  Error,
  Info,
  Warning
}
