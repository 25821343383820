/**
 * These are the IV modules names as used in the
 * featuretoggle table, these are case sensitive
 * make sure this matches as you have in
 *
 * @export
 * @class Constants
 */
export class IVModules {
  static readonly ACCOUNT_MANAGEMENT = 'Account Management';
  static readonly ANNOUNCEMENTS = 'Announcements';
  static readonly AUDIT_RECORDS = 'Audit Records';
  static readonly BLOCK_NUMBERS = 'Block Numbers';
  static readonly BUSINESS_HOURS = 'Business Hours';
  static readonly BUY_NUMBER = 'Buy Number';
  static readonly CLI_LIST = 'CLI List';
  static readonly CALLFLOW_EDITOR = 'Call Flow Editor';
  static readonly CALL_LOGS = 'Call Logs';
  static readonly CALL_REPORTS = 'Call Reports';
  static readonly COST_CENTER = 'Cost Center';
  static readonly COUNTRIES = 'Countries';
  static readonly COUNTRIES_TO_COUNTRIES_RESTRICTED = 'Countries to Countries Restricted';
  static readonly COUNTRY_GROUPS = 'Country Groups';
  static readonly COUNTRY_RESTRICTION = 'Country Restriction';
  static readonly CUSTOMER = 'Customer';
  static readonly CUSTOMER_SETTINGS = 'Customer Settings';
  static readonly DASHBOARD = 'Dashboard';
  static readonly DATE_LIST = 'Date List';
  static readonly DELIVERY_GROUP = 'Delivery Group';
  static readonly DESTINATION_COUNTRIES = 'Destination Countries';
  static readonly DESTINATION_NUMBER = 'Destination Number';
  static readonly GLOBAL_VARIABLES = 'Global Variables';
  static readonly GROUPS = 'Groups';
  static readonly INBOUND_NUMBER_LIST = 'Inbound Number List';
  static readonly INBOUND_NUMBER_PORTING = 'Inbound Number Porting';
  static readonly INBOUND_NUMBERS = 'Inbound Numbers';
  static readonly LEGAL_ENTITIES = 'Legal Entities';
  static readonly LISTS = 'Lists';
  static readonly MY_NUMBERS = 'My Numbers';
  static readonly NUMBER_PORTING = 'Number Porting';
  static readonly PERSISTENT_VARIABLES = 'Persistent Variables';
  static readonly REGULATORY_INFORMATION = 'Regulatory Information';
  static readonly REGUALTORY_REQUIREMENT = 'Regulatory Requirement';
  static readonly REPORTS = 'Reports';
  static readonly SETTINGS = 'Settings';
  static readonly SETUP_MRC = 'Setup MRC';
  static readonly TRANSIENT_VARIABLES = 'Transient Variables';
  static readonly TWILIO_ADDRESSES ='Twilio Addresses';
  static readonly USER = 'User';
  static readonly VARIABLES = 'Variables';
  static readonly AUDIO_ANNOUNCEMENT = 'Audio Files';
  static readonly QUEUE = 'Queues';
}
