/* eslint-disable */
import {Injectable} from '@angular/core';
import {Validation} from './validation.component';
import {HttpHeaders} from '@angular/common/http';
import {environment} from './environments/environment';

//// audit records
import {ActivityType, ActivityModule, AutoSaveConst, Generic} from './app/shared/common';
import {LoginConstants} from './app/login/login.constants';
import {AutoSaveService} from './app/shared/services/autosave.service';
//timezone DST adjustment
import * as moment from 'moment';
import 'moment-timezone';
declare var require: any;

@Injectable()


export class Global {
  public static limitPerPage = 25;
  public static apifilename = environment['apiConfigFile'];


  public static logout(error = undefined) {
    let dateObj = new Date();
    var month = new Array();
    month[0] = "Jan";
    month[1] = "Feb";
    month[2] = "Mar";
    month[3] = "Apr";
    month[4] = "May";
    month[5] = "Jun";
    month[6] = "Jul";
    month[7] = "Aug";
    month[8] = "Sep";
    month[9] = "Oct";
    month[10] = "Nov";
    month[11] = "Dec";
    let logoutTime = `${dateObj.getDate()} ` + month[dateObj.getMonth()] + ` ${dateObj.getFullYear()} ${dateObj.getHours()}:${dateObj.getMinutes()}`;

    // let logoutTime = `${dateObj.getDate()}-${dateObj.getMonth() + 1}-${dateObj.getFullYear()} ${dateObj.getHours()}:${dateObj.getMinutes()}`;
    let userName = sessionStorage.getItem("userName");
    let userType = sessionStorage.getItem("userType");
    let activityModule = (userType == "Administrator" ? ActivityModule.ADMINPORTAL : ActivityModule.CUSTOMERPORTAL)

    let auditRecordItem = {
      SIN: sessionStorage.getItem("SIN"),
      orgId: sessionStorage.getItem("orgId"),
      activityModule: activityModule,
      activityType: ActivityType.LOGOUT,
      userName: userName,
      changes: {"newData": {"info": ` User -${userName} logged out of ${activityModule}. `}},
      IsAdministrator: (userType == "Administrator" || userType == "Administrator on Demand" ? true : false)
    };

    sessionStorage.setItem('loggedOutUserDetails', JSON.stringify(auditRecordItem));
    sessionStorage.setItem('isAuditRecordAddedForLogout', 'false');
    sessionStorage.removeItem('isAuditRecordAddedForLogin');
    sessionStorage.removeItem('isLoggedin');
    sessionStorage.removeItem('userID');
    sessionStorage.removeItem('userPermission');
    sessionStorage.removeItem('organization');
    sessionStorage.removeItem('orgId');
    sessionStorage.removeItem('x-iv-hdr');
    sessionStorage.removeItem('correlationId');
    sessionStorage.removeItem('xServiceId');
    sessionStorage.removeItem('SIN');
    sessionStorage.removeItem('userName');
    sessionStorage.removeItem('userType');
    sessionStorage.removeItem('loggedInUserDetails');
    sessionStorage.removeItem('isAuditRecordAddedForLogin');
    sessionStorage.removeItem('moreFields');
    sessionStorage.removeItem('chartLables');
    sessionStorage.removeItem('inboundArray');
    sessionStorage.removeItem('inboundArrayMonthly');
    sessionStorage.removeItem('lastActivityTime');
    sessionStorage.removeItem('sessionStartTime');
    sessionStorage.removeItem('refreshToken');
    sessionStorage.removeItem('savedData');
    sessionStorage.removeItem('allNavigation');
    sessionStorage.removeItem('mainNavigation');
    sessionStorage.removeItem('userSessionId');
    sessionStorage.removeItem('corelationId');
    sessionStorage.removeItem('email');
    sessionStorage.removeItem('userAccess');
    sessionStorage.removeItem('userGuide')
    sessionStorage.removeItem('timeZone');
    window.location.replace('/login');
  }

  public static accessDeny(childClass) {
    window.location.replace('/access-denied');
  }

  public static counterSession(dis) {
    var sessionActive = environment['cognito']['cognitoLoginTimeSpan'];
    var lastActivityTime = parseInt(sessionStorage.getItem('lastActivityTime'));
    var d2 = Math.floor(new Date().getTime());

    var calculate = (sessionActive * 1000 * 60) - (Math.floor(new Date().getTime()) - parseInt(sessionStorage.getItem('lastActivityTime')));

    var t1 = Math.floor(calculate / 1000);

    var sec = t1 % 60;
    var secToString = sec < 10 ? '0' + sec : sec;
    var min = (t1 - sec) / 60;
    var minToString = min < 10 ? '0' + min : min;
    if (t1 > 0) {
      dis.remaningTime = minToString + ":" + secToString;
    } else {
      dis.remaningTime = "Logging out";
    }

    if (lastActivityTime + (sessionActive * 1000 * 60) <= d2) {
      Global.logout('');
      sessionStorage.setItem('sessionTimeOut', 'true');
    }
  }

  /**
   * This method will try to track the usersession for every 10seconds
   * If user is inactive for 30 or more mins, then user will be logged out directly unless
   * the user is not in the URL which is mentioned as AUTO_SAVE_URLS in
   * login.constants.ts
   *
   * @static
   * @param {*} dis
   * @memberof Global
   */
  public static refreshToken(dis) {

    // Defining all the timings
    let currentSessionTime = Math.floor(new Date().getTime());
    let lastActivityTime = parseInt(sessionStorage.getItem('lastActivityTime'));
    let sessionStartTime = parseInt(sessionStorage.getItem('sessionStartTime'));
    let timeToRefreshToken = environment.refreshAccessTokenTime * 60 * 1000; // in milliseconds
    let inactiveSessionTime = environment.inactiveSessionLogoutTime * 60 * 1000; // in milliseconds

    // Trying to check if the user is inactive for the mentioned time(in env file)
    // if below condition is true, then user will be logged out
    if (currentSessionTime - lastActivityTime >= inactiveSessionTime) {
      sessionStorage.setItem('sessionTimeOut', LoginConstants.REGULAR);

      // if it matches any of the URL then sessionTimeout sets a different message
      var checkAutoSaveFn = (url: string): boolean => {
        var lastVisitedPage = sessionStorage.getItem(`lastVisitedPage_${sessionStorage.getItem('userID')}`);
        if (sessionStorage.getItem('userType') === Generic.ADMIN_ON_DEMAND)
          lastVisitedPage = sessionStorage.getItem(`lastVisitedPage_${Generic.ADMIN_ON_DEMAND}_${sessionStorage.getItem('userID')}`);

        if (lastVisitedPage.indexOf(url) !== -1) {
          sessionStorage.setItem('sessionTimeOut', LoginConstants.AUTOSAVED);

          // trigering the AutoSave based on the URLs mentioned in the ENV file
          if (AutoSaveConst.CALL_FLOW_EDITOR === url) {
            AutoSaveService.changeAutoSaveCallFlowPage(true);
          }
          return true;
        } else return false;
      }
      // Trying to check if the lastvisitedPage is matches the autoSave URL
      environment.autoSaveURLs.some(checkAutoSaveFn);
      if (sessionStorage.getItem('sessionTimeOut') === LoginConstants.REGULAR) AutoSaveService.changeLogoutTrigger(true); // if user is not in autosave url page

    } else {
      /* else if the user is active, trying to update the accessToken before it expires
         this whole thing is done in the background */
      if (currentSessionTime - sessionStartTime >= timeToRefreshToken) {
        var headers = {};
        var options = {};
        headers['Content-Type'] = 'application/json';
        headers['Authorization'] = sessionStorage.getItem('token');
        let httpHeaders = new HttpHeaders(headers);
        options = {headers: httpHeaders};
        var payLoad = {"operation": "regenerateToken", "refreshToken": sessionStorage.getItem('refreshToken')};
        var apiRequestItem = dis.encryptService.encryption(JSON.stringify(payLoad));
        var urls = this.requireBlock(this.apifilename);
        var userID = sessionStorage.getItem('userID');
        sessionStorage.removeItem('userID');
        // firing API to regenerate AccessToken
        dis.http.post(urls['logIN'], apiRequestItem, options).subscribe(returnData => {
          var data = dis.encryptService.decryption(returnData.data);
          if (typeof (data['token']) !== 'undefined') {
            sessionStorage.setItem('userID', userID);
            sessionStorage.setItem('token', data['token']);
            sessionStorage.setItem('sessionStartTime', new Date().getTime().toString());
            return true;
          }
        }, (error: Error) => {
          console.error("USER ACCESS TOKEN FETCH FAILED:")
          console.error(error.stack);
        });
      }
    }
  }


  /**
   * Method sends API User from the API config files
   * also it updates the lastActivityTime of the user
   * while trying to fire APIs
   *
   * @static
   * @param {*} key
   * @returns
   * @memberof Global
   */
  public static getAPIURI(key) {
    sessionStorage.setItem('lastActivityTime', new Date().getTime().toString());
    var urls = this.requireBlock(this.apifilename);
    return urls[key] != undefined ? urls[key] : "Not configured yet";
  }

  public static formValidation(childClass, validation, formName = 'formValue') {
    // console.log('sdsfd');
    validation = Validation.validationjson[validation];
    childClass.validationError = {};
    var eachValidField = Object.keys(validation);
    for (var j = 0; j < eachValidField.length; j++) {
      var val = childClass[formName][eachValidField[j]];
      var allValidation = validation[eachValidField[j]];
      var keyValidation = Object.keys(allValidation);
      var errorKey = eachValidField[j];
      for (var i = 0; i < keyValidation.length && childClass.validationError[errorKey] == undefined; i++) {
        var msg = allValidation[keyValidation[i]]['msg'];
        switch (keyValidation[i]) {
          case "required":
            if (val == undefined || val == '' || val == 'undefined') {
              childClass.validationError[errorKey] = msg;
            }
            break;
          case "email":
            var regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
            if (val != undefined && val != '' && !regexp.test(val)) {
              childClass.validationError[errorKey] = msg;
            }
            break;
          case "min":
            if (val != undefined && val != '' && val.length < allValidation[keyValidation[i]]['count']) {
              childClass.validationError[errorKey] = msg;
            }
            break;
          case "max":
            if (val != undefined && val != '' && val.length > allValidation[keyValidation[i]]['count']) {
              childClass.validationError[errorKey] = msg;
            }
            break;
          case "nonEmail":
            var regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
            if (val != undefined && val != '' && regexp.test(val)) {
              childClass.validationError[errorKey] = msg;
            }
            break;
          case "alphaCSNumericSymbol":
            var patternCapital = /[A-Z]/g;
            var patternSmall = /[a-z]/g;
            var patternNumber = /[0-9]/g;
            var patternSymbol = /[-!$%^&*()_+|~=`{}\[\]:";'<>?,.\/@#]/g;
            if (val != undefined && val != '' && val.match(patternCapital) == null) {
              childClass.validationError[errorKey] = msg;
            } else if (val != undefined && val != '' && val.match(patternSmall) == null) {
              childClass.validationError[errorKey] = msg;
            } else if (val != undefined && val != '' && val.match(patternNumber) == null) {
              childClass.validationError[errorKey] = msg;
            } else if (val != undefined && val != '' && val.match(patternSymbol) == null) {
              childClass.validationError[errorKey] = msg;
            }
            break;
          case "alphaNumericOnly":
            var letters = /^[0-9a-zA-Z]+$/;
            if (!val.match(letters)) {
              childClass.validationError[errorKey] = msg;
            }
            break;
          case "alphaNumericSpaceOnly":
            var letters = /^[0-9a-zA-Z ]+$/;
            if (!val.match(letters)) {
              childClass.validationError[errorKey] = msg;
            }
            break;
          case "phoneNumberOnly":
            let phoneNumber = /^\+[1-9]\d{9,14}$/;
            if (!val.match(phoneNumber)) {
              childClass.validationError[errorKey] = msg;
            }
            break;
          case "orgName":
            var letters = /^^[a-zA-Z\d' ]+$/;
            if (!val.match(letters)) {
              childClass.validationError[errorKey] = msg;
            }
            break;
          case "oneKeyRequired":
            if (val == undefined || !Object.keys(val).length) {
              childClass.validationError[errorKey] = msg;
            }
            break;
          case "greaterThan":
            if (val != undefined && childClass.formValue[allValidation[keyValidation[i]]['field']] != undefined && childClass.formValue[allValidation[keyValidation[i]]['field']] > val) {
              childClass.validationError[errorKey] = msg;
            }
            break;
          case "positiveInteger":
            if (val != undefined && val < 0) {
              childClass.validationError[errorKey] = msg;
            }
            break;
          case "positiveIntegerOnly":
            if ((val != undefined && val < 1) || parseInt(val) != val) {
              childClass.validationError[errorKey] = msg;
            }
            break;
          case "addressValidation":
            var letters = /^[a-zA-Z\u0080-\u024F\d (?:., |-| |')]+[a-zA-Z0-9\u0080-\u024F]$/;
            if (!val.match(letters)) {
              childClass.validationError[errorKey] = msg;
            }
            break;

          default: childClass.validationError[errorKey] = "UI error " + keyValidation[i] + " validation not defined";
        }

      }
    }
  }

  public static clearReportStorage(childClass) {
    sessionStorage.removeItem('inboundArray');
    sessionStorage.removeItem('abandonedArray');
    sessionStorage.removeItem('chartLables');
  }

  //returning require block as per environments
  public static requireBlock(env_name) {
    switch (env_name) {
      case 'env1': return require('./api-config/env1.json');
      case 'env2': return require('./api-config/env2.json');
      case 'env3': return require('./api-config/env3.json');
      case 'dev': return require('./api-config/dev.json');
      case 'stage': return require('./api-config/stage.json');
      case 'prod': return require('./api-config/prod.json');
    }
  }

  public static userFriendlyError(statusCode, strError) {
    var friendlyMsg: string;

    // All error with statuscode less than 500
    if (statusCode >= 400 && statusCode < 500) {
      friendlyMsg = strError;
      // for module forgot password
      if (strError.indexOf('UserNotFoundException') != -1) friendlyMsg = "Username / EmailId is not found";
      if (strError.indexOf('LimitExceededException') != -1) friendlyMsg = "Attempt limit exceeded, please try after some time";
      if (strError.indexOf('UserNotConfirmedException') != -1) friendlyMsg = "Cannot reset password for an unconfirmed user";

      // for module confirm forgot password
      if (strError.indexOf('CodeMismatchException') != -1) friendlyMsg = "Reset code doesnot match";
      if (strError.indexOf('ExpiredCodeException') != -1) friendlyMsg = "Reset code has expired";
      if (strError.indexOf('InvalidPasswordException') != -1) friendlyMsg = "Invalid password entered";
      if (strError.indexOf('MissingRequiredParameter') != -1) friendlyMsg = "Please fill all the required fields";

    } else {
      // for all messages with status code 500
      friendlyMsg = "Sever error encountered. Please contact administrator.";

      // destination number
      if (strError.indexOf('destination number') != -1) friendlyMsg = "Invalid destination number";
      if (strError.indexOf('ValidationException') != -1) friendlyMsg = "Both fields are mandatory";
    }

    return friendlyMsg;
  }

  /**
   * this method will add ellipsis
   * @param {string} text
   * @param {integer} max
   * @return {string} str
   * @memberof CallFlowListComponent
   */
  public static truncateWithEllipses(text, max): string {
    return text.substr(0, max - 1) + (text.length > max ? '...' : '');
  }


  public static customFormValidation(childClass, validation) {
    let validationError = {};
    validation = Validation.validationjson[validation];
    validationError = {};
    let eachValidField = Object.keys(validation);
    for (let j = 0; j < eachValidField.length; j++) {
      let val = childClass[eachValidField[j]];
      //trim is applicable only for string. for type of numbers not required.
      if (typeof (val) == "string") val = val.trim();

      let allValidation = validation[eachValidField[j]];
      let keyValidation = Object.keys(allValidation);
      let errorKey = eachValidField[j];
      for (let i = 0; i < keyValidation.length && validationError[errorKey] == undefined; i++) {
        let msg = allValidation[keyValidation[i]]['msg'];
        switch (keyValidation[i]) {
          case "required":
            if (val == undefined || val == '' || val == 'undefined') {
              validationError[errorKey] = msg;
            }
            break;
          case "email":
            let regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
            if (val != undefined && val != '' && !regexp.test(val)) {
              validationError[errorKey] = msg;
            }
            break;
          case "min":
            if (val != undefined && val != '' && val.length < allValidation[keyValidation[i]]['count']) {
              validationError[errorKey] = msg;
            }
            break;
          case "max":
            if (val != undefined && val != '' && val.length > allValidation[keyValidation[i]]['count']) {
              validationError[errorKey] = msg;
            }
            break;
          case "nonEmail":
            let regexpNonEmail = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
            if (val != undefined && val != '' && regexpNonEmail.test(val)) {
              validationError[errorKey] = msg;
            }
            break;
          case "alphaCSNumericSymbol":
            let patternCapital = /[A-Z]/g;
            let patternSmall = /[a-z]/g;
            let patternNumber = /[0-9]/g;
            let patternSymbol = /[-!$%^&*()_+|~=`{}\[\]:";'<>?,.\/@#]/g;
            if (val != undefined && val != '' && val.match(patternCapital) == null) {
              validationError[errorKey] = msg;
            } else if (val != undefined && val != '' && val.match(patternSmall) == null) {
              validationError[errorKey] = msg;
            } else if (val != undefined && val != '' && val.match(patternNumber) == null) {
              validationError[errorKey] = msg;
            } else if (val != undefined && val != '' && val.match(patternSymbol) == null) {
              validationError[errorKey] = msg;
            }
            break;
          case "alphaNumericOnly":
            let letter = /^[0-9a-zA-Z]+$/;
            if (!val.match(letter)) {
              validationError[errorKey] = msg;
            }
            break;
          case "alphaNumericSpaceOnly":
            let letters = /^[0-9a-zA-Z ]+$/;
            if (!val.match(letters)) {
              validationError[errorKey] = msg;
            }
            break;
          case "phoneNumberOnly":
            let phoneNumber = /^\+[1-9]\d{9,14}$/;
            if (!val.match(phoneNumber)) {
              validationError[errorKey] = msg;
            }
            break;
          case "oneKeyRequired":
            if (val == undefined || !Object.keys(val).length) {
              validationError[errorKey] = msg;
            }
            break;
          case "greaterThan":
            if (val != undefined && childClass.formValue[allValidation[keyValidation[i]]['field']] != undefined && childClass.formValue[allValidation[keyValidation[i]]['field']] > val) {
              validationError[errorKey] = msg;
            }
            break;
          case "positiveInteger":
            if (val != undefined && val < 0) {
              validationError[errorKey] = msg;
            }
            break;
          case "positiveIntegerOnly":
            if ((val != undefined && val < 1) || parseInt(val) != val) {
              validationError[errorKey] = msg;
            }
            break;


          default: validationError[errorKey] = "UI error " + keyValidation[i] + " validation not defined";
        }
      }
    }
    return validationError;
  }

  public static mimetypeToExtenction(mimetypes: any[]) {
    const extenctionsList = {
      "audio/mpeg": ".mp3",
      "audio/wav": ".wav",
      "audio/wave": ".wave",
      "audio/x-wav": ".xwav",
      "audio/aiff": ".aiff",
      "audio/x-aifc": ".aifc",
      "audio/x-aiff": ".aiff",
      "audio/x-gsm": ".gsm",
      "audio/gsm": ".gsm",
      "audio/ulaw": ".ulaw"
    };
    let exttypes = mimetypes.map(a => {
      return extenctionsList[a];
    })

    const ss = Array.from(new Set(exttypes));
    return ss;
  }

  /**
   * This method will adjust UTC offset value as per DST 
   * moment-timezone provides DST on/off
   * override the timezone array 
   * @param {object} tz
   * @returns {object} timezones
   * @memberof Global
   */
  public static adjustTzToDST(tz: any[]) {
    let timezones = [];

    tz.forEach((currentVal) => {
      try {
        // for every timezone, do DST time transformation
        if (currentVal.label != 'Default') {
          let itemArray = currentVal.label.split(" ")
          itemArray[0] = "(UTC" + moment.tz(currentVal.value).format("Z") + ")"
          timezones.push({"ust": parseInt(moment.tz(currentVal.value).format("Z").replace(":", "")), "label": itemArray.join(" "), "value": currentVal.value})
        } else {
          timezones.push({"ust": 0, "label": 'Default', "value": 'Default'})
        }
        //error handling - returns the origin list 
      } catch (e) {
        console.log('error while loading DST configuration')
        timezones.push(currentVal)
      }
    })
    timezones.push({"ust": 0, "label": '(UTC+00:00) Coordinated Universal Time', "value": 'UTC'})
    //Sorting based on the UST time (+1:00 or -1:00)
    timezones.sort((a, b) => (a.ust > b.ust ? 1 : -1));
    return timezones;
  }

  /**
   * This method will adjust UTC offset value as per DST 
   * moment-timezone provides DST on/off
   * override the timezone array 
   * @returns void
   * @memberof Global
   */
  public static hardReload() {
    //AMIT hard refresh to reload DOM, as Virtual DOM by react is creating some manipulation - coming from react callflow editor - UC24504
    //Need to revisit TODO
    if (sessionStorage.getItem("prevPage") == "rcfe") {
      window.location.reload();
      sessionStorage.removeItem('prevPage');
    }
    //////////////////
  }

}
