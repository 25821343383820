import {Component, OnInit, Input} from '@angular/core';

import {Alert, AlertType} from '../_models/index';
import {AlertService} from '../_services/index';
import {AlertConst} from './../alert.constants';

@Component({
  moduleId: module.id,
  selector: 'alert',
  templateUrl: 'alert.component.html',
  styleUrls: ['./alert.component.scss']
})

/**
 * Alert component will be used to pass onthe
 * alert business logic to its view
 *
 * @export
 * @class AlertComponent
 */
export class AlertComponent {
  @Input() id: string;
  showAlert: boolean = true;

  alerts: Alert[] = [];

  /**
   *Creates an instance of AlertComponent.
   * @param {AlertService} alertService
   * @memberof AlertComponent
   */
  constructor(private alertService: AlertService) {}

  /**
   * Implements the onInit method to add initial business logic
   *
   * @memberof AlertComponent
   */
  ngOnInit(): void {
    this.alertService.getAlert(this.id).subscribe((alert: Alert): void => {
      if (!alert.message) {
        // clear alerts when an empty alert is received
        this.alerts = [];
        return;
      }

      // add alert to array
      this.alerts.push(alert);
    });
  }

  /**
   * Adds logic to remove the any kind of alert box
   *
   * @param {Alert} alert
   * @memberof AlertComponent
   */
  removeAlert(alert: Alert): void {
    this.alerts = this.alerts.filter((x): boolean => x !== alert);
  }

  /**
   * Adds the css class for the alert box based on the alert type
   *
   * @param {Alert} alert
   * @return {string}
   * @memberof AlertComponent
   */
  cssClass(alert: Alert): string {
    if (!alert) {
      return;
    }

    // return css class based on alert type
    switch (alert.type) {
      case AlertType.Success:
        return `${AlertConst.ALERT_CLASS} ${AlertConst.ALERT_SUCCESS}`;
      case AlertType.Error:
        return `${AlertConst.ALERT_CLASS} ${AlertConst.ALERT_ERROR}`;
      case AlertType.Info:
        return `${AlertConst.ALERT_CLASS} ${AlertConst.ALERT_INFO}`;
      case AlertType.Warning:
        return `${AlertConst.ALERT_CLASS} ${AlertConst.ALERT_WARNING}`;
    }
  }

  /**
   * Adds the icon to corresponding alert based on the alert type
   *
   * @param {Alert} alert
   * @return {string}
   * @memberof AlertComponent
   */
  iconClass(alert: Alert): string {
    if (!alert) {
      return;
    }

    // return icon class based on alert type
    switch (alert.type) {
      case AlertType.Success:
        return `${AlertConst.ICON_SUCCESS}`;
      case AlertType.Error:
        return `${AlertConst.ICON_ERROR}`;
      case AlertType.Info:
        return `${AlertConst.ICON_INFO}`;
      case AlertType.Warning:
        return `${AlertConst.ICON_WARNING}`;
    }
  }
}
