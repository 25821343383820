/* eslint-disable no-invalid-this*/

import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable()

/**
 * This service is used to delegate the auto save related
 * task which was subscribed by the various components
 * the properties and methods are dedicated for specific
 * module of the portal
 *
 * @export
 * @class CommonService
 * @extends {BaseService}
 */
export class AutoSaveService {
  // callflow related modules
  public static isAutoSaveCallFlowPage$ = new BehaviorSubject<boolean>(false); // dedicated property for Callflow Module
  public static isCallFlowSaved$ = new BehaviorSubject<boolean>(false); // dedicated property for saveCallflow function

  // global related modules
  public static triggerLogout$ = new BehaviorSubject<boolean>(false); // dedicated property to global modules
  /**
   * Method to toggle isAutoCallFlowPage
   *
   * @static
   * @param {boolean} isTriggedOnCFPage
   * @memberof AutoSaveService
   */
  static changeAutoSaveCallFlowPage(isTriggedOnCFPage: boolean): void {
    AutoSaveService.isAutoSaveCallFlowPage$.next(isTriggedOnCFPage);
  }
  /**
   * Method to toggle isCallFlowSaved method
   *
   * @static
   * @param {boolean} status
   * @memberof AutoSaveService
   */
  static changeCallFlowStatus(status: boolean): void {
    AutoSaveService.isCallFlowSaved$.next(status);
  }
  /**
   * Method to trigger the change logout trigger
   *
   * @static
   * @param {boolean} status
   * @memberof AutoSaveService
   */
  static changeLogoutTrigger(status: boolean): void {
    AutoSaveService.triggerLogout$.next(status);
  }
}
