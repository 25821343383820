import {NgModule} from '@angular/core';
// import {NgbModule} from '@ng-bootstrap/ng-bootstrap'
import {FormsModule} from '@angular/forms'
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {LinkedNumbersComponent} from './linked-numbers.component';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {LinkedNumbersService} from './linked-numbers.service';
import {IvrService} from './../../../layout/ivr/ivr.service';
@NgModule({
  declarations: [LinkedNumbersComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    BsDatepickerModule.forRoot()
  ],
  exports: [LinkedNumbersComponent],
  providers: [LinkedNumbersService, IvrService]
})

/**
 *
 *
 * @export
 * @class LinkedNumberModule
 */
export class LinkedNumbersModule {}
