import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PermissionCheckDirective} from './permissioncheck.directive';


@NgModule({
  declarations: [PermissionCheckDirective],
  imports: [
    CommonModule
  ],
  exports: [PermissionCheckDirective]
})

/**
 *
 *
 * @export
 * @class PermissionCheckModule
 */
export class PermissionCheckModule { }
