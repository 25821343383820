
/*eslint-disable*/
import {throwError as observableThrowError} from 'rxjs';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/finally';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {map, catchError} from 'rxjs/operators';
import {error} from 'selenium-webdriver';

@Injectable()

export class BaseService {
  userType: string;
  constructor(public http: HttpClient) {
    this.userType = sessionStorage.getItem('userType');
  }

  // Get methods with or without options
  get(url, options?: any) {
    // to add correlationId for UCP-5220
    options = this.customHeaders(options);

    if (options) {
      // Helper service to start ng2-slim-loading-bar progress bar
      return this.http.get(url, options)
        .pipe(
          map((res) => {return res}),
          catchError((error) => this.customErrorHandler(error))
        );
    } else {
      // Helper service to start ng2-slim-loading-bar progress bar
      return this.http.get(url)
        .pipe(
          map((res) => {return res}),
          catchError((error) => this.customErrorHandler(error))
        );
    }
  }
  // POST methods with or without options
  post(url, postBody: any, options?: any,  slipOrgID?: boolean) {
    // to avoid adding anything to the audit payload
    if (this.userType == 'Administrator on Demand' && options['module'] == undefined) {
      if (postBody["role"] == undefined && postBody["role"] !== "Customer Administrator" && slipOrgID==undefined) {
        postBody["orgId"] = sessionStorage.getItem('orgId');
      }
    }
    // to add correlationId for UCP-5220
    options = this.customHeaders(options);

    if (options) {
      return this.http.post(url, postBody, options)
        .pipe(
          map((res) => {return res}),
          catchError((error) => this.customErrorHandler(error))
        );
    } else {
      return this.http.post(url, postBody)
        .pipe(
          map((res) => {return res}),
          catchError((error) => this.customErrorHandler(error))
        );
    }


  }

  // Delete methods with or without options
  delete(url, options?: any) {
    // to add correlationId for UCP-5220
    options = this.customHeaders(options);

    if (options) {
      return this.http.delete(url, options)
        .pipe(
          map((res) => {return res}),
          catchError((error) => this.customErrorHandler(error))
        );
    } else {
      return this.http.delete(url)
        .pipe(
          map((res) => {return res}),
          catchError((error) => this.customErrorHandler(error))
        );
    }

  }

  // PUT methods with or without options
  put(url, putData: any, options?: any) {
    // to add correlationId for UCP-5220
    options = this.customHeaders(options);

    if (options) {
      return this.http.put(url, putData, options)
        .pipe(
          map((res) => {return res}),
          catchError((error) => this.customErrorHandler(error))
        );
    } else {
      return this.http.put(url, putData)
        .pipe(
          map((res) => {return res}),
          catchError((error) => this.customErrorHandler(error))
        );
    }

  }

  customHeaders(options: any) {
    if (options) {
      let headers: HttpHeaders = options.headers;
      if(sessionStorage.getItem('correlationId')) headers = headers.set('x-correlation-id', sessionStorage.getItem('correlationId'));
      if(sessionStorage.getItem('xServiceId'))  headers=headers.set('x-service-id',sessionStorage.getItem('xServiceId'))
      if(sessionStorage.getItem('x-iv-hdr'))  headers=headers.set('x-iv-hdr',sessionStorage.getItem('x-iv-hdr'))
      if(sessionStorage.getItem('userType') == 'Administrator on Demand')  headers=headers.delete('orgId')
      //support for backward compatibility AMIT/PAVAN
      if(headers) headers=headers.set('x-service-version',options['x-service-version'] || "2")
      options['headers'] = headers;
      return options;
    } else {
      return options;
    }
  }

  /**
   * Method to handle the custom error handler for '403' global logout issue
   *
   * @return {error}
   * @memberof BaseService
   */
  customErrorHandler(error: any) {
    if (error['status'] == 403) {
      error['status'] = 4001;
      if (!error['error']['message']) {
        error['error']['message'] = "User don't have permission"
      }
      return observableThrowError(error);
    } else {
      return observableThrowError(error)
    }
  };

}
