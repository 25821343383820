

import {SearchOptions} from './search-options';
import {SearchType} from './search-type.enum'
/**
 * Search config class will assist
 * in creating the object for reactive form
 *
 * @export
 * @class SearchConfig
 */
export class SearchConfig {
  static searchConfigs: object[] = [];


  /**
   * This method initializes the Searchconfig Object
   *
   * @static
   * @memberof SearchConfig
   */
  public static initConfig(): void {
    SearchConfig.searchConfigs = [];
  }
  /**
   * This method creates a new search filter field in the UI
   *
   * @param {string} field
   * @param {string} searchType
   * @param {string} placeHolder
   * @param {string} defaultValue
   * @memberof SearchConfig
   */
  public static setConfig(field: string, searchType: SearchType, placeHolder: string, defaultValue: any): void {
    const objConfig = {};
    if ((typeof(field)==='undefined' || field == null || field == '')) return;
    objConfig['field'] = field;
    objConfig['searchType'] = searchType;
    objConfig['placeHolder'] = placeHolder;
    objConfig['defaultValue'] = defaultValue;
    SearchConfig.searchConfigs.push(objConfig);
  }

  /**
   * This method sets the selectOptions if the filter value
   * has a select filter type
   *
   * @param {string} field
   * @param {SearchOptions} searchOptions
   * @memberof SearchConfig
   */
  public static setSelectOptions(field: string, searchOptions: SearchOptions[]): void {
    SearchConfig.searchConfigs.find((searchConfig: object): boolean=>{
      if (searchConfig['field']===field && searchConfig['searchType']===SearchType.SELECT) {
        searchConfig['selectOptions'] = searchOptions;
        return true;
      }
    })
  }

  /**
   * This method returns the final SearchConfig that needs
   * to be sent as the input
   *
   * @return {object}
   * @memberof SearchConfig
   */
  public static getConfig(): object[] {
    return SearchConfig.searchConfigs;
  }

  /**
   * This method sets the defaultvalue of searchConfig based on key name
   *
   * @param {string} field
   * @param {SearchOptions} searchOptions
   * @memberof SearchConfig
   */
  public static setDefaultValue(field: string, defaultValue: any): void {
    SearchConfig.searchConfigs.find((searchConfig: object): boolean => {
      if (searchConfig['field'] === field) {
        searchConfig['defaultValue'] = defaultValue;
        return true;
      }
    })
  }
}
