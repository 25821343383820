/**
 * This class represents the activity module
 * for the entire IV application
 *
 * @export
 * @class ActivityModule
 */
export class ActivityModule {
  public static LOGIN = 'Login';
  public static USERS = 'Users';
  public static CUSTOMERS = 'Customers';
  public static LOGOUT = 'Logout';
  public static ADDRESS = 'Addresses';
  public static MRCSETUP = 'MRC Setup';
  public static CALLREPORTS = 'Call Reports';
  public static ENTITIES = 'Legal Entities';
  public static ADMINPORTAL = 'Admin Portal';
  public static ANNOUNCEMENT = 'Regulatory Notifications';
  public static BLOCKNUMBERS = 'Block Numbers';
  public static COSTCENTER = 'Business Groups';
  public static COUNTRYGROUPS = 'Country Groups';
  public static INBOUNDNUMBERS = 'Inbound Numbers';
  public static CUSTOMERPORTAL = 'Customer Portal';
  public static DESTINATIONNUMBERS = 'Destination Numbers';
  public static CALLLOGS = 'Call Logs';
  public static FORGOTPASSWORD = 'Forgot Password';
  public static CONFIRMPASSWORD = 'Confirm Password';
  public static DESTINATIONCOUNTRY = 'Destination Country';
  public static COUNTRIESRESTRICTED = 'Country Restriction';
  public static PORTNUMBER = 'Number Provision';
  public static REGULATORYREQUIREMENTS = 'Regulatory Requirements';
  public static DELIVERYGROUPS = 'Delivery Groups';
  public static INBOUNDGROUPS = 'Inbound Number List';
  public static CALLFLOW = 'Call Flow';
  public static CALLFLOWVERSION = 'Call Flow Version';
  public static CALLFLOWEDITOR = 'Call Flow';
  public static GLOBALVARIABLES = 'Global Variables';
  public static TRANSIENTVARIABLES = 'Transient Variables';
  public static PERSISTENTVARIABLES='Persistent Variables'
  public static CLILIST = 'CLI List';
  public static CUSTOMERSETTINGS = 'Customer Settings'
  public static BUSINESSHOURS = 'Business Hours';
  public static DATELIST = 'Date List';
  public static REGULATORYBUNDLE = 'Regulatory Bundle';
  public static BUYNUMBERS='Buy Numbers';
  public static AUDIOANNOUNCEMENT = 'Audio Announcement List';
  public static VARIABLES = 'Variables';
  public static QUEUES = 'Queues';
}
