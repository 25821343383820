/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./alert.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./alert.component";
import * as i4 from "../_services/alert.service";
var styles_AlertComponent = [i0.styles];
var RenderType_AlertComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AlertComponent, data: {} });
export { RenderType_AlertComponent as RenderType_AlertComponent };
function View_AlertComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["aria-hidden", "true"]], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "alert-msgbox"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", "\u00A0\u00A0\u00A0"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "a", [["class", "close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeAlert(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.cssClass(_v.context.$implicit), " alert-mod alert-dismissable"); _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵinlineInterpolate(1, "fa ", _co.iconClass(_v.context.$implicit), "  fa-2x alert-pad"); _ck(_v, 1, 0, currVal_1); var currVal_2 = _v.context.$implicit.message; _ck(_v, 3, 0, currVal_2); }); }
export function View_AlertComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlertComponent_1)), i1.ɵdid(1, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.alerts; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AlertComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "alert", [], null, null, null, View_AlertComponent_0, RenderType_AlertComponent)), i1.ɵdid(1, 114688, null, 0, i3.AlertComponent, [i4.AlertService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AlertComponentNgFactory = i1.ɵccf("alert", i3.AlertComponent, View_AlertComponent_Host_0, { id: "id" }, {}, []);
export { AlertComponentNgFactory as AlertComponentNgFactory };
