
import {filter} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Router, NavigationStart} from '@angular/router';
import {Observable, Subject} from 'rxjs';
import {AlertConst} from './../alert.constants';
import {Alert, AlertType} from '../_models/alert';

@Injectable()

/**
 * This service is used by alert component
 * which will be used by every other module
 * to display information, warning or error message as
 * an alert box
 *
 * @export
 * @class AlertService
 */
export class AlertService {
  private subject = new Subject<Alert>();
  private keepAfterRouteChange = false;

  /**
   *Creates an instance of AlertService.
   * @param {Router} router
   * @memberof AlertService
   */
  constructor(private router: Router) {
    // clear alert messages on route change unless 'keepAfterRouteChange' flag is true
    router.events.subscribe((event): void => {
      if (event instanceof NavigationStart) {
        if (this.keepAfterRouteChange) {
          // only keep for a single route change
          this.keepAfterRouteChange = false;
        } else {
          // clear alert messages
          this.clear();
        }
      }
    });
  }


  /**
   * To get corresponding alert box based on alert id
   *
   * @param {string} [alertId]
   * @return {Observable<any>}
   * @memberof AlertService
   */
  getAlert(alertId?: string): Observable<any> {
    return this.subject.asObservable().pipe(filter((x: Alert): boolean => x && x.alertId === alertId));
  }


  /**
   * Method to display the success box
   *
   * @param {string} message
   * @param {number} [timeout=AlertConst.TIMEOUT]
   * @memberof AlertService
   */
  success(message: string, timeout: number = AlertConst.TIMEOUT): void {
    this.alert(new Alert({message, type: AlertType.Success}));
    this.clearAlert(timeout);
  }

  /**
   * Method to display the error box
   *
   * @param {string} message
   * @param {number} [timeout=AlertConst.TIMEOUT]
   * @memberof AlertService
   */
  error(message: string, timeout: number = AlertConst.TIMEOUT): void {
    this.alert(new Alert({message, type: AlertType.Error}));
    this.clearAlert(timeout);
  }

  /**
   * Method to display the info box
   *
   * @param {string} message
   * @param {number} [timeout=AlertConst.TIMEOUT]
   * @memberof AlertService
   */
  info(message: string, timeout: number = AlertConst.TIMEOUT): void {
    this.alert(new Alert({message, type: AlertType.Info}));
    this.clearAlert(timeout);
  }

  /**
   * Method to display the warn box
   *
   * @param {string} message
   * @param {number} [timeout=AlertConst.TIMEOUT]
   * @memberof AlertService
   */
  warn(message: string, timeout: number = AlertConst.TIMEOUT): void {
    this.alert(new Alert({message, type: AlertType.Warning}));
    this.clearAlert(timeout);
  }


  /**
   * Method to display the alert box
   *
   * @param {Alert} alert
   * @memberof AlertService
   */
  alert(alert: Alert): void {
    this.keepAfterRouteChange = alert.keepAfterRouteChange;
    this.subject.next(alert);
  }


  /**
   * Method to clear the existing alert timeout
   *
   * @param {string} [alertId]
   * @memberof AlertService
   */
  clear(alertId?: string): void {
    this.subject.next(new Alert({alertId}));
  }


  /**
   * Method to clear the existing alert box
   *
   * @param {number} [timeout=AlertConst.TIMEOUT]
   * @memberof AlertService
   */
  clearAlert(timeout: number = AlertConst.TIMEOUT): void {
    setTimeout((): void => {
      this.clear();
    }, timeout);
  }
}
