import {Injectable} from '@angular/core';
import {Observable, BehaviorSubject} from 'rxjs';
import {SharedData} from '../interfaces';

@Injectable()


export class GeneralService {
  public profileObs$: BehaviorSubject<SharedData> = new BehaviorSubject(null);
  public buyNumberObs$: BehaviorSubject<SharedData> = new BehaviorSubject(null);

  getProfileObs(): Observable<SharedData> {
    // console.log('Get new', this.profileObs$.asObservable());
    return this.profileObs$.asObservable();
  }

  setProfileObs(profile: SharedData) {
    this.profileObs$.next(profile);
  }

  resetProfileObs() {
    this.profileObs$.next(null);
  }

  setBuyNumber(profile: SharedData) {
    this.buyNumberObs$.next(profile);
  }

  resetBuyNmber() {
    this.buyNumberObs$.next(null);
  }

  getBuyNmber(): Observable<SharedData> {
    return this.buyNumberObs$.asObservable();
  }
}
