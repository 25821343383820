import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClientModule, HttpClient} from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AuthGuard, GeneralService} from './shared';
import {EncryptService} from './shared';
import {BaseService} from './shared';
import {RouterModule, Routes} from '@angular/router';
import {DashboardComponent} from "./layout/dashboard/dashboard.component";
import {FormsModule} from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {LoginService} from './login/login.service';

// import alert service and component
import {AlertComponent} from './alert/_directives/index';
import {AlertService} from './alert/_services/index';
import {SocketIoModule, SocketIoConfig} from 'ng-socket-io';
const config: SocketIoConfig = {url: '', options: {transports: ['websocket', 'polling'], port: 443, secure: true}};

// AoT requires an exported function for factories
export function createTranslateLoader(http: HttpClient) {
  // for development
  // return new TranslateHttpLoader(http, '/start-angular/SB-Admin-BS4-Angular-5/master/dist/assets/i18n/', '.json');
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    SocketIoModule.forRoot(config),
    AppRoutingModule,
    RouterModule,

    NgbModule.forRoot()
  ],
  declarations: [AppComponent, AlertComponent],
  providers: [AuthGuard, BaseService, HttpClientModule, AlertService, EncryptService, GeneralService, LoginService],
  bootstrap: [AppComponent]
})
export class AppModule {}
