/**
 * Constants to be used in AutoService
 * related features
 *
 * @export
 * @class AutoSaveURLModules
 */
export class AutoSaveConst {
  static readonly CALL_FLOW_EDITOR = 'call-flow-editor/CF'; // Callflow editor URL
}
