import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeleteComponent } from './delete.component';
import { DeleteService } from './delete.service';

@NgModule({
  declarations: [DeleteComponent],
  imports: [
    CommonModule
  ],
  exports: [DeleteComponent],
  providers: [DeleteService]
})

/*
 * @export
 * @class DeleteModuleModule
*/
export class DeleteModule {}
