import {Constants} from './constants';
import {Pipe, PipeTransform} from '@angular/core';
import {DatePipe} from '@angular/common';


@Pipe({
  name: 'dateFormatWithOutYear'
})
/**
 * Pipe to define date without year
 * for Year agnostic module
 *
 * @export
 * @class DateWithoutYearFormatPipe
 * @extends {DatePipe}
 * @implements {PipeTransform}
 */
export class DateWithoutYearFormatPipe extends DatePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return super.transform(value, (args && args.length>0)?Constants.MONTH_FMT:Constants.DATE_FMT_WITHOUT_YEAR);
  }
}
