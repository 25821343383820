import { Routes } from '@angular/router';
import { AuthGuard } from './shared';
const ɵ0 = () => import("./layout/layout.module.ngfactory").then((m) => m.LayoutModuleNgFactory), ɵ1 = () => import("./login/login.module.ngfactory").then((m) => m.LoginModuleNgFactory), ɵ2 = () => import("./login/login.module.ngfactory").then((m) => m.LoginModuleNgFactory), ɵ3 = () => import("./forgot-password/forgot-password.module.ngfactory").then((m) => m.ForgotPasswordModuleNgFactory), ɵ4 = () => import("./confirm-password/confirm-password.module.ngfactory").then((m) => m.ConfirmPasswordModuleNgFactory), ɵ5 = () => import("./signup/signup.module.ngfactory").then((m) => m.SignupModuleNgFactory), ɵ6 = () => import("./server-error/server-error.module.ngfactory").then((m) => m.ServerErrorModuleNgFactory), ɵ7 = () => import("./webrtc/webrtc.module.ngfactory").then((m) => m.WebrtcModuleNgFactory), ɵ8 = () => import("./access-denied/access-denied.module.ngfactory").then((m) => m.AccessDeniedModuleNgFactory), ɵ9 = () => import("./not-found/not-found.module.ngfactory").then((m) => m.NotFoundModuleNgFactory), ɵ10 = () => import("./user-guide/user-guide.module.ngfactory").then((m) => m.UserGuideModuleNgFactory), ɵ11 = () => import("./approve-mrc-request/approve-mrc-request.module.ngfactory").then((m) => m.ApproveMRCRequestModuleNgFactory), ɵ12 = () => import("./supplier-bill/supplier-bill.module.ngfactory").then((m) => m.SupplierBillModuleNgFactory), ɵ13 = () => import("./queues-status/queues-status.module.ngfactory").then((m) => m.QueuesStatusModuleNgFactory), ɵ14 = () => import("./not-found/not-found.module.ngfactory").then((m) => m.NotFoundModuleNgFactory);
const routes = [
    { path: '', loadChildren: ɵ0, canActivate: [AuthGuard] },
    { path: 'login', loadChildren: ɵ1 },
    { path: 'login/:approvalID', loadChildren: ɵ2 },
    { path: 'forgot', loadChildren: ɵ3 },
    { path: 'confirm', loadChildren: ɵ4 },
    { path: 'signup', loadChildren: ɵ5 },
    { path: 'error', loadChildren: ɵ6 },
    { path: 'webrtc', loadChildren: ɵ7 },
    { path: 'access-denied', loadChildren: ɵ8 },
    { path: 'not-found', loadChildren: ɵ9 },
    { path: 'user-guide', loadChildren: ɵ10 },
    { path: 'approve-mrc-request/:ID', loadChildren: ɵ11 },
    { path: 'supplier-bill/:ID', loadChildren: ɵ12 },
    { path: 'queues-status', loadChildren: ɵ13 },
    { path: '**', loadChildren: ɵ14 }
];
// eslint-disable-next-line require-jsdoc
export class AppRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14 };
