import { Constants } from './constants';
import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'timeWithSecondsFormat'
})
export class TimeWithSecondsFormatPipe extends DatePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return super.transform(value, Constants.TIMEWITHSECONDS_FMT);
  }
}